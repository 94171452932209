import NeonImage from '@/assets/neon-background-2.jpg';
import { activeHeaderState } from '@/recoil/listState';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Header from '../Header/Header';
import styles from "./Layout.module.scss";

const Layout = () => {
  
  const activeHeader = useRecoilValue(activeHeaderState);

  return (
    <div className={styles["root"]}>
      <div>
        <Header />
        <div className={styles["content-container"]}>
          <img src={NeonImage} alt='' />
          <div className={styles["title"]}>{activeHeader}</div>
          <div className={classNames(styles["content"])}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;