import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Layout from './components/Layout/Layout';

import BlockDetail from './pages/Blocks/BlockDetail';
import Blocks from './pages/Blocks/Blocks';
import Transactions from './pages/Transactions/Transactions';
import ValidatorDetail from './pages/Validators/ValidatorDetail';
import Validators from './pages/Validators/Validators';


import '@/styles/variable.scss';
import HomePage from './pages/Home/Home';
import ProposalPage from './pages/Proposal/Proposal';
import TransactionDetail from './pages/Transactions/TransactionDetail';

const App = () => {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="blocks" element={<Blocks />} />
            <Route path="blocks/:id" element={<BlockDetail />} />
            <Route path="validators" element={<Validators />} />
            <Route path="validators/:id" element={<ValidatorDetail />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transactions/:id" element={<TransactionDetail />} />
            <Route path="proposal" element={<ProposalPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
};


export default App;