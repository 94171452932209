import { CircularProgress } from "@mui/material";
import { fetchCurrentValidatorsList } from '@/apis/store_api.ts';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from "./DetailPage.module.scss";
import SignedBlock from './SignedBlock/SignedBlock';
import ValidatorDetail from './ValidatorInformation/ValidatorDetail';
import { useSetRecoilState } from "recoil";
import { activeHeaderState } from "@/recoil/listState";

const DetailPage = () => {
  const { id } = useParams();

  const [validationDetail, setValidationDetail] = useState();
  const [loading, setLoading] = useState(true);
  const setActiveHeader = useSetRecoilState(activeHeaderState);

  const fetchData = async () => {
    try {
      setLoading(true);

      const dataList = await fetchCurrentValidatorsList();
      const data = dataList.find(item => item.address === id);

      setValidationDetail(data || {});
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle errors if needed
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setActiveHeader("Validator");
    fetchData();
  }, [id]);

  return (
    <div className={styles["root"]}>
      <div className={styles["container"]}>
        {loading ? (
          <CircularProgress /> // Customize size and thickness as needed
        ) : (
          <>
            <ValidatorDetail data={validationDetail} />
          </>
        )}
      </div>
    </div>
  );
};

export default DetailPage;
