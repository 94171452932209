import Logo from '@/assets/namada-icon.svg';
import { activeHeaderState } from '@/recoil/listState';
import SearchIcon from '@mui/icons-material/Search';
import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styles from "./Header.module.scss";

const Header = () => {
  const navigate = useNavigate();
  const searchRef = useRef();
  // const rpcUrl = process.env.REACT_APP_NEXT_PUBLIC_RPC_URL;
  const setActiveHeader = useSetRecoilState(activeHeaderState);
  const navigationList = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Block",
      link: "/blocks",
    },
    {
      text: "Validator",
      link: "/validators",
    },
    {
      text: "Transaction",
      link: "/transactions",
    },
    {
      text: "Proposal",
      link: "/proposal",
    },
  ];

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    const searchData = searchRef.current.value.trim();
    if (searchData.length > 0) {
      if (searchData.length === 64) {
        navigate(`/transactions/${searchData}`);
      } else if (searchData.length === 40) {
        navigate(`/validators/${searchData}`);
      }
      else {
        navigate(`/blocks/${searchData}`);
      }
    }
  };

  return (
    <div className={styles['root']}>
      <div className={styles['container']}>
        <div className={styles['navigation-container']}>
          <div className={styles['logo-container']}>
            <div className={styles["logo"]}><img src={Logo} alt="logo" /></div>
          </div>
          <div className={styles['navigation-bar']}>
            {
              navigationList.map((item, index) => 
                <Link key={`navigation-${index}`} to={item.link || "/"} className={styles['navigation-item']} onClick={() => setActiveHeader(item.text)}>
                  <div className={styles['navigation-text']}>{item.text}</div>
                </Link>
              )
            }
          </div>
        </div>
        <div className={styles['search-form']}>
          <input
            ref={searchRef}
            type="text"
            id="search"
            name="search"
            className={styles['search']}
            style={{ width: '600px' }}
            placeholder=" height / tx / validator address"
            onKeyDown={handleKeyPress}
          />
          <SearchIcon className={styles['search-icon']} onClick={handleSearch} />
        </div>
      </div>
    </div>
  );
};

export default Header;