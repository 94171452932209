import { activeHeaderState, activeLinkState } from '@/recoil/listState';
import { CircularProgress, Tooltip } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styles from "./Proposal.module.scss";
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';

const Proposal = () => {
  const setActiveLink = useSetRecoilState(activeLinkState);
  const apiRef = useGridApiRef();
  const [initData, setInitData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const setActiveHeader = useSetRecoilState(activeHeaderState);
  const [searchData, setSearchData] = useState("");

  const columns = [
    { field: 'id', headerName: 'ID', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false },
    { field: 'author', headerName: 'Author', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 500, sortable: false },
    { field: 'kind', headerName: 'Type', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 180, sortable: false, renderCell: (data) => <span> {<span className={styles["kind"]}>{data.row.kind}</span>} </span>  },
    { field: 'result', headerName: 'Result', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false, renderCell: (data) => <span> {<span className={data.row.result === "Rejected" ? styles["reject"] : data.row.result === "Passed" ? styles["passed"] : data.row.result === "Pending" ? styles["pending"] : styles["voting"]}>{data.row.result}</span>} </span>  },
    { field: 'start_epoch', headerName: 'Start Epoch', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 160, sortable: false },
    { field: 'end_epoch', headerName: 'End Epoch', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 160, sortable: false },
    { field: 'grace_epoch', headerName: 'Grace Epoch', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 160, sortable: false },
    { field: 'votes', headerName: 'Votes', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 250, sortable: false, 
      renderCell: (data) => {
        const total = Number(data.row.yay_votes) + Number(data.row.nay_votes) + Number(data.row.abstain_votes);
        
        return (
          <Tooltip title={
            <div className={styles["tooltip-data"]}>
              <div>Yay votes: {Math.round(data.row.yay_votes / total * 100 * 100) / 100}%</div>
              <div>Nay votes: {Math.round(data.row.nay_votes / total * 100 * 100) / 100}%</div>
              <div>Abstain votes: {Math.round(data.row.abstain_votes / total * 100 * 100) / 100}%</div>
            </div>} 
            arrow 
            className={styles["vote-bar"]}
          >
            <div className={styles["vote-bar-yay"]} style={{width: `${data.row.yay_votes / total * 100}%`}}></div>
            <div className={styles["vote-bar-nay"]} style={{width: `${data.row.nay_votes / total * 100}%`}}></div>
            <div className={styles["vote-bar-abstain"]} style={{width: `${data.row.abstain_votes / total * 100}%`}}></div>
          </Tooltip>
        )
    } },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const fetchData = async () => {
    const res = await fetch("https://it.api.namada.red/api/v1/chain/governance/proposals");
    const data = await res.json();

    console.log(data);
    if (data && data.proposals) {
      const finalData = data.proposals.map((item, index) => ({ ...item, index: index + 1, author: item.author.Account })).sort((a, b) => b.id - a.id)

      setData(finalData);
      setInitData(finalData);
      setIsloading(false);
    }
  }

  // const getDetailData = async (visibleDataList) => {
  //   setIsloading(true);
  //   return fetchSpecificValidatorsWithDetails(visibleDataList).then(data => {
  //     setDetailDataList(data);
  //     setIsloading(false);
  //   });
  // }

  const searchKeyword = (e) => {
    setSearchData(e.target.value.toLowerCase());
    setInitData((old) => {
      const res = old.filter(obj => Object.values(obj).some(val => val?.toString().toLowerCase().includes(e.target.value.toLowerCase())));
      setData(res);

      return old;
    })
  }

  const debounceSearch = useRef(debounce((nextValue) => searchKeyword(nextValue), 500)).current;

  useEffect(() => {
    //init data
    setActiveLink("/proposal");
    setActiveHeader("Proposal");
    fetchData();
  }, [setActiveLink]);

  useEffect(() => {
    //get and fetch detail of the visible data in the table view
    // const visibleDataList = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef.current.state);
    // getDetailData(visibleDataList || initData.slice(0, 10));
  }, [paginationModel, apiRef, initData]);

  // useEffect(() => {
  //   //map missing data to the list
  //   setData(oldData => oldData.map(item => ({ ...item, ...detailDataList.find(detail => detail.validator === item.address) })));
  // }, [detailDataList]);

  return (
    <div className={styles["root"]}>
      {
        isLoading ? <CircularProgress /> :
        <div className={styles["container"]}>
          <div className={styles["title-container"]}>
            <form className={styles["search-form"]}>
              <input type="text" id="search" name="search" className={styles["search"]} placeholder="Author" onChange={(e) => debounceSearch(e)} />
              <SearchIcon className={styles["search-icon"]} />
            </form>
          </div>
          <DataGrid
            apiRef={apiRef}
            getRowId={(row) => row.index}
            rows={data}
            columns={columns}
            pageSizeOptions={[10]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? styles["row-even"] : styles["row-odd"]
            }
            disableColumnFilter
          />
        </div>
      }
    </div>
  );
};

export default Proposal;