import { formatDistanceToNow } from 'date-fns';
import { useEffect, useRef } from "react";
import styles from "./BlockInformations.module.scss";

const BlockInformations = (props) => {
  const { data: block, setElementHeight } = props;

  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      setElementHeight(ref.current.clientHeight);
    }
  }, [setElementHeight]);

  return (
    <div className={styles["root"]}>
      <div className={styles["container"]}>
        <div className={styles["title"]}>Block Details</div>
        <div className={styles["line"]}></div>
        <div className={styles['details-container']} ref={ref}>
          {
            block && block.block_id ? <>
              <div className={styles["content"]}><span>Height: </span>{block?.header.height}</div>
              <div className={styles["content"]}><span>Hash: </span>{block?.block_id}</div>
              <div className={styles["content"]}><span>No of Txs: </span>{block?.tx_hashes.length}</div>
              <div className={styles["content"]}><span>Proposer: </span> <a href={`/validators/${block?.header.proposer_address}`}>{block?.header.proposer_address}</a> </div>
              <div className={styles["content"]}><span>Time: </span>{formatDistanceToNow(new Date(block?.header.time), { addSuffix: true })}  <br />{block?.header.time}</div>
            </> : <div className={styles["not-found"]}>Not found!</div>
          }
        </div>
      </div>
    </div>
  );
};

export default BlockInformations;