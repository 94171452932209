import { fetchBlockByHash, fetchTransactions } from '@/apis/store_api.ts';
import { activeHeaderState, activeLinkState } from '@/recoil/listState';
import { CircularProgress, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format, formatDistanceToNow } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styles from "./Transactions.module.scss";

const Transactions = () => {
  const setActiveLink = useSetRecoilState(activeLinkState);
  const [txs, setTxs] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const blocks = new Map(); // Assuming blocks is a Map
  const fetchDataInterval = useRef(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const setActiveHeader = useSetRecoilState(activeHeaderState);

  var isFetchingData = false;
  const fetchData = async () => {
    try {
      if (isFetchingData === true && paginationModel.page === 0) {
        return;
      }
      if (paginationModel.page > 0) {
        setIsloading(true);
      }

      const res = await fetchTransactions(paginationModel.page + 1, paginationModel.pageSize);
      isFetchingData = true;
      const data = res.data;

      const newBlockPromises = new Map(); // Create a new blockPromises map for each call

      const updatedTxs = [];

      for (let index = 0; index < data.length; index++) {
        const tx = data[index];
        blocks.clear();
        const block = blocks.get(tx.block_id);

        if (!block) {
          let blockPromise = newBlockPromises.get(tx.block_id); // Use the new blockPromises map

          if (!blockPromise) {
            blockPromise = await fetchBlockByHash(tx.block_id);
            blocks.set(tx.block_id, {
              blockId: tx.block_id,
              height: blockPromise.header.height,
              time: blockPromise.header.time,
            });
            newBlockPromises.delete(tx.block_id);
          }

          const updatedTx = {
            index: index + 1,
            height: blocks.get(tx.block_id).height,
            hash: tx.hash,
            time: blocks.get(tx.block_id).time,
            returnCode: tx.return_code,
            txType: tx.tx_type,
            tx: tx.tx,
          };

          updatedTxs.push(updatedTx);
        } else {
          const updatedTx = {
            index: index + 1,
            height: block.height,
            hash: tx.hash,
            time: block.time,
            returnCode: tx.return_code,
          };

          updatedTxs.push(updatedTx);
        }
      }

      setTxs(updatedTxs);
      setRowCountState(res?.total || 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      isFetchingData = false;
      setIsloading(false);
    }
  };

  useEffect(() => {
    setActiveLink("/transactions");
    setActiveHeader("Transaction");
    fetchData();

    //Set up interval for fetching new data every 4 seconds
    fetchDataInterval.current = setInterval(() => {
      if (paginationModel.page === 0) {
        fetchData();
      }
    }, 6000);

    //Clear the interval when the component unmounts
    return () => clearInterval(fetchDataInterval.current);
  }, [setActiveLink, paginationModel.page, paginationModel.pageSize]);

  const columns = [
    { field: 'index', headerName: 'No', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false, renderCell: (data) => <span> {<span>{data.row.index + (paginationModel.page) * paginationModel.pageSize}</span>} </span> },
    { field: 'hash', headerName: 'Tx', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 600, sortable: false },
    { field: 'height', headerName: 'Height', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false, renderCell: (data) => <a href={`/blocks/${data.value}`}>{data.value}</a> },
    { field: 'txType', headerName: 'Type', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false, renderCell: (data) => data?.row.tx && Object.keys(data?.row.tx).length > 0 ? Object.keys(data?.row.tx)[0] : data?.row.txType },
    {
      field: 'returnCode',
      headerName: 'Shielded', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false, renderCell: (data) => data.row.tx &&
        data.row.tx.Transfer &&
        data.row.tx.Transfer.shielded
        ? 'Yes'
        : 'No'
    },
    {
      field: 'tx', headerName: 'Status', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false,
      renderCell: (data) => (
        <span
          style={{
            color: data?.row.returnCode === 0 || data?.row.txType === 'Wrapper' ? 'green' : 'red',
          }}
        >
          {data?.row.returnCode === 0 || data?.row.txType === 'Wrapper' ? "Success" : "Fail"}
        </span>
      )
    },
    { field: 'time', headerName: 'Time', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false, renderCell: (data) => <Tooltip title={format(new Date(data.value), 'yyyy/MM/dd hh:mm:ss')} arrow> <span>{formatDistanceToNow(new Date(data.value), { addSuffix: true })}</span> </Tooltip> },
  ];

  return (
    <div className={styles["root"]}>
      <div className={styles["container"]}>
        {txs.length === 0 && !isLoading && <p>No results found</p>}
        {
          isLoading ? <CircularProgress />  : 
          <DataGrid
            getRowId={(row) => row.index}
            rows={txs}
            columns={columns}
            pageSizeOptions={[10]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            disableColumnFilter
            paginationMode="server"
            pageSize={paginationModel.pageSize}
            rowCount={rowCountState}
            loading={isLoading}
          />
        }
      </div>
    </div>
  );
};

export default Transactions;