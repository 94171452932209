import { useEffect, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchBlockByHash, fetchBlocks, fetchTransactions } from '@/apis/store_api.ts';
import { activeHeaderState, activeLinkState } from '@/recoil/listState';
import { useSetRecoilState } from 'recoil';
import styles from "./Home.module.scss";
import { formatDistanceToNow, format } from 'date-fns';
import { CircularProgress, Tooltip } from '@mui/material';
import classNames from 'classnames';

const Home = () => {
  const setActiveLink = useSetRecoilState(activeLinkState);
  const setActiveHeader = useSetRecoilState(activeHeaderState);
  const [generalData, setGeneralData] = useState();
  const [activeValidatorData, setActiveValidatorData] = useState();
  const [latestBlockData, setLatestBlockData] = useState([]);
  const transactionBlock = new Map(); // Assuming blocks is a Map
  const [txs, setTxs] = useState([]);

  const [isLoading, setIsloading] = useState(true);
  const [isBlockLoading, setIsBlockLoading] = useState(true);
  const [isTransactionLoading, setIsTransactionLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const fetchDataInterval = useRef(null);

  const fetchData = async () => {
    try {
      try {
        const generalRes = await fetch("https://namada-explorer-api.stakepool.dev.br/node/blocks/block/latest");
        const generalData = await generalRes.json();
        const activeValidatorRes = await fetch("https://namada.api.explorers.guru/api/validators");
        const activeValidatorData = await activeValidatorRes.json();

        if (generalData && activeValidatorData) {
          setGeneralData(generalData);
          setActiveValidatorData(activeValidatorData.validators.length);
          setIsloading(false);
        }
      } catch {
        console.log("error");
      }

      

      const latestBlockData = await fetchBlocks(1, 10);
      
      if (latestBlockData && latestBlockData.data) {
        const finalData = latestBlockData.data.map((block, index) => ({
          ...block,
          index: index + 1,
          height: block.header.height,
          hash: block.block_id,
          txs: block.tx_hashes.length,
          time: block.header.time,
          proposer: block.header.proposer_address,
        }));
        setLatestBlockData(finalData);
        setIsBlockLoading(false);
      }

      
      const transactionRes = await fetchTransactions(1, 10);
      const transactionData = transactionRes.data;
      const newBlockPromises = new Map(); // Create a new blockPromises map for each call

      const updatedTxs = [];

      for (let index = 0; index < transactionData.length; index++) {
        const tx = transactionData[index];
        transactionBlock.clear();
        const block = transactionBlock.get(tx.block_id);

        if (!block) {
          let blockPromise = newBlockPromises.get(tx.block_id); // Use the new blockPromises map

          if (!blockPromise) {
            blockPromise = await fetchBlockByHash(tx.block_id);
            transactionBlock.set(tx.block_id, {
              blockId: tx.block_id,
              height: blockPromise.header.height,
              time: blockPromise.header.time,
            });
            newBlockPromises.delete(tx.block_id);
          }

          const updatedTx = {
            index: index + 1,
            height: transactionBlock.get(tx.block_id).height,
            hash: tx.hash,
            time: transactionBlock.get(tx.block_id).time,
            returnCode: tx.return_code,
            txType: tx.tx_type,
            tx: tx.tx,
          };

          updatedTxs.push(updatedTx);
        } else {
          const updatedTx = {
            index: index + 1,
            height: block.height,
            hash: tx.hash,
            time: block.time,
            returnCode: tx.return_code,
          };

          updatedTxs.push(updatedTx);
        }
      }

      setTxs(updatedTxs);
      setIsTransactionLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    setActiveLink("/");
    setActiveHeader("Home");
    fetchData();
    // //Set up interval for fetching new data every 4 seconds
    fetchDataInterval.current = setInterval(() => {
      if (paginationModel.page === 0) {
        fetchData();
      }
    }, 5000);

    //Clear the interval when the component unmounts
    return () => clearInterval(fetchDataInterval.current);
  }, [setActiveLink, paginationModel.page, paginationModel.pageSize]);

  const blockColumns = [
    { field: 'index', headerName: 'No', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 50, sortable: false, renderCell: (data) => <span> {<span>{data.row.index}</span>} </span> },
    { field: 'height', headerName: 'Height', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false, renderCell: (data) => <a href={`/blocks/${data.value}`}>{data.value}</a> },
    { field: 'hash', headerName: 'Hash', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 200, sortable: false, renderCell: (data) => <Tooltip title={data.value} arrow>  <span> {data.value.substring(0, 6)}...{data.value.substring(data.value.length - 6)} </span></Tooltip> },
    { field: 'txs', headerName: 'Txs', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false },
    { field: 'proposer', headerName: 'Proposer', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 150, sortable: false, renderCell: (data) => <a href={`/validators/${data.value}`}><Tooltip title={data.value} arrow>  <span> {data.value.substring(0, 6)}...{data.value.substring(data.value.length - 6)} </span></Tooltip></a> },
    { field: 'time', headerName: 'Time', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 150, sortable: false, renderCell: (data) => <Tooltip title={format(new Date(data.value), 'yyyy/MM/dd hh:mm:ss')} arrow> <span>{formatDistanceToNow(new Date(data.value), { addSuffix: true })}</span></Tooltip> },
  ];

  const transactionColumns = [
    { field: 'index', headerName: 'No', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 50, sortable: false, renderCell: (data) => <span> {<span>{data.row.index + (paginationModel.page) * paginationModel.pageSize}</span>} </span> },
    { field: 'hash', headerName: 'Tx', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 150, sortable: false, renderCell: (data) => <Tooltip title={data.value} arrow> <a href={`/transactions/${data.value}`}><span> {data.value.substring(0, 6)}...{data.value.substring(data.value.length - 6)}</span></a> </Tooltip> },
    { field: 'height', headerName: 'Height', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false, renderCell: (data) => <a href={`/blocks/${data.value}`}>{data.value}</a> },
    { field: 'txType', headerName: 'Type', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false, renderCell: (data) => data?.row.tx && Object.keys(data?.row.tx).length > 0 ? Object.keys(data?.row.tx)[0] : data?.row.txType },
    {
      field: 'returnCode',
      headerName: 'Shielded', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false, renderCell: (data) => data.row.tx &&
        data.row.tx.Transfer &&
        data.row.tx.Transfer.shielded
        ? 'Yes'
        : 'No'
    },
    {
      field: 'tx', headerName: 'Status', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 100, sortable: false,
      renderCell: (data) => (
        <span
          style={{
            color: data?.row.returnCode === 0 || data?.row.txType === 'Wrapper' ? 'green' : 'red',
          }}
        >
          {data?.row.returnCode === 0 || data?.row.txType === 'Wrapper' ? "Success" : "Fail"}
        </span>
      )
    },
    { field: 'time', headerName: 'Time', headerClassName: styles["table-header"], cellClassName: styles["table-cell"], width: 150, sortable: false, renderCell: (data) => <Tooltip title={format(new Date(data.value), 'yyyy/MM/dd hh:mm:ss')} arrow> <span>{formatDistanceToNow(new Date(data.value), { addSuffix: true })}</span> </Tooltip> },
  ];

  return (
    <div className={styles["root"]}>
      <div className={styles["container"]}>
        <div className={styles["general-info"]}>
          <div className={styles["info-item"]}>
            <div className={styles["info-title"]}>Chain ID</div>
            <div className={styles["info-data"]}>{isLoading ? <CircularProgress /> : generalData?.header_chain_id}</div>
          </div>
          <div className={styles["info-item"]}>
            <div className={styles["info-title"]}>Block height</div>
            <div className={styles["info-data"]}>{isLoading ? <CircularProgress /> : generalData?.header_height}</div>
          </div>
          <div className={styles["info-item"]}>
            <div className={styles["info-title"]}>Block time</div>
            <div className={styles["info-data"]}>{isLoading ? <CircularProgress /> : (generalData?.header_time && format(new Date(generalData?.header_time), 'yyyy/MM/dd hh:mm:ss'))}</div>
          </div>
          <div className={styles["info-item"]}>
            <div className={styles["info-title"]}>active validator</div>
            <div className={styles["info-data"]}>{isLoading ? <CircularProgress /> : activeValidatorData}</div>
          </div>
        </div>
        <div className={styles["table-container"]}>
          <div className={classNames(styles["table-block"], isBlockLoading && styles["table-block--loading"])}>
            <div className={styles["table-block-title"]}>Latest 10 block</div>
            {
              isBlockLoading ? <CircularProgress /> : 
              <DataGrid
                getRowId={(row) => row.index}
                rows={latestBlockData}
                columns={blockColumns}
                pageSizeOptions={[10]}
                paginationModel={paginationModel}
                disableColumnFilter
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? styles["row-even"] : styles["row-odd"]
                }
              />
            }
          </div>

          <div className={classNames(styles["table-transaction"], isTransactionLoading && styles["table-transaction--loading"])}>
            <div className={styles["table-block-title"]}>Latest 10 Transaction</div>
            {
              isTransactionLoading ? <CircularProgress /> : 
              <DataGrid
                getRowId={(row) => row.index}
                rows={txs}
                columns={transactionColumns}
                pageSizeOptions={[10]}
                paginationModel={paginationModel}
                disableColumnFilter
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
