import Home from '@/components/Pages/Home/Home';

const HomePage = () => {

  return (
    <>
      <Home />
    </>
  );
};

export default HomePage;