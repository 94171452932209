import Proposal from '@/components/Pages/Proposal/Proposal';

const ProposalPage = () => {

  return (
    <>
      <Proposal />
    </>
  );
};

export default ProposalPage;